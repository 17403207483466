const Twitter = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM28.9747 10.8594H25.8734L20.7631 16.7009L16.3447 10.8594H9.94531L17.5916 20.8578L10.3447 29.1406H13.4478L19.0409 22.7497L23.9291 29.1406H30.17L22.1994 18.6031L28.9747 10.8594ZM26.5034 27.2844H24.785L13.5669 12.6181H15.4109L26.5034 27.2844Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Twitter
